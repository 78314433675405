<template>
    <connector
        class='connector'
        close_to="/account"
    ></connector>
</template>

<script>
import Connector from '../components/connector.vue'
/*
@desc Connection page (wrapper for `connector` component)
*/
export default {
    components: { Connector }
}
</script>

<style>
.connector {
    max-width: 100%;
    width: 500px !important;
}
</style>