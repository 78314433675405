// ----------------------------------------------------------------------- UTILS

import { format } from 'date-fns'
import Duration from 'duration'
import { i18n } from '../main'

function check_date(date) {
    return typeof date == 'date' ? date : new Date(date)
}

function find_object(root, path) {
    let obj = root
    path.split('.').forEach(elm => obj ? obj = obj[elm] : obj)
    return obj
}

const utils = {

    check_date,

    wait: (ms) => new Promise(ok => setTimeout(ok, ms)),

    get_url_args() {
        return Object.fromEntries(location.href.split('?').pop().split('&').map(a => a.split('=')))
    },

    async get_paged_data(api_method) {
        const resp = await api_method(0)
        const { totalPages } = resp
        if (totalPages - 1 <= 0) return resp.content
        const all_resps = await Promise.all(Array(totalPages - 1).fill(0).map((_, i) => api_method(i + 1)))
        const contents = [resp, ...all_resps].map(r => r.content).flat()
        return contents
    },
    add_zeros(nb, min = 2) {
        nb = String(nb)
        return nb.length < min ? '0'.repeat(min - nb.length) + '' + nb : nb
    },
    format: (date) => format(check_date(date), i18n.t('utils.datetimeformat')),
    spend: (date1, date2) => {
        const spent = new Duration(check_date(date1), check_date(date2))
        return spent.toString(1, 3).replace('d', 'j')
    },
    time_is_in_past: (date) => check_date(date).getTime() < Date.now(),
    sort_by_date: (array, date_prop, reverse = false) =>
        array.sort((a, b) =>
            check_date(find_object(reverse ? b : a, date_prop)).getTime() -
            check_date(find_object(reverse ? a : b, date_prop)).getTime()
        )
}

// ----------------------------------------------------------------------- EXPORTS

import Vue from 'vue'
export default { install: () => Vue.mixin({ beforeCreate() { this.$utils = utils } }) }