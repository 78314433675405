<template>
    <div
        class='back-illus'
        v-if="current_image_data"
    >
        <span
            v-for="type in image_types"
            :key="type"
        >
            <div :class="'main_transform transform_'+type">
                <v-img
                    :class="'back_img '+type+'_img transitioner'"
                    :id="'back_img_'+type"
                    :src="current_image_data[type].src"
                    :width="current_image_data[type].width"
                    :style="{
                    ...current_image_data[type].style
                }"
                />
            </div>
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex'
/*
@desc Background Illustration Engine
*/
export default {
    props: ['back_data'],
    // -----------------------------------------------------
    data: () => ({
        route: null,
        image_types: ['left', 'right', 'bottom'],
        current_image_data: null,
        to: null,
        // ----------------------------------------------------- CUSTOM DATA
        images: [
            // ------------------------------------ CART
            {
                routes: ['cart'],
                images: ['left', 'right', 'bottom'],
                from: {
                    margin: '0px -200px',
                    opacity: 0
                },
                style: {
                    margin: '0px -120px',
                    opacity: 0.3,
                },
                sub_filters: [
                    { images: ['right'], states: ['contains'], src: '/images/illus/shopping.png' },
                    { images: ['left'], states: ['empty'], src: '/images/illus/looking.png' },
                    {
                        images: ['bottom'],
                        is_mobile: true,
                        states: ['empty'],
                        src: '/images/illus/looking.png',
                        from: {
                            margin: '-180px 0px',
                            opacity: 0
                        },
                        style: {
                            margin: '-150px 0px',
                            opacity: 0.3
                        },
                    }
                ]
            },
            // ------------------------------------ CGU
            {
                routes: ['cgu'],
                images: ['left'],
                src: '/images/illus/balance.png',
                from: {
                    margin: '0px -200px',
                    opacity: 0,
                },
                style: {
                    margin: '0px -100px',
                    opacity: 0.3,
                },
            },
            // ------------------------------------ USER
            {
                routes: ['account'],
                images: ['left'],
                from: {
                    margin: '0px -200px',
                    opacity: 0,
                },
                style: {
                    margin: '0px -100px',
                    opacity: 0.3,
                },
                sub_filters: [
                    { states: ['infos'], src: '/images/illus/rose.png', },
                    { states: ['admin'], src: '/images/illus/shield.png', }
                ]
            },
            // ------------------------------------ ORDER RESULT
            {
                routes: ['order_result', 'activate'],
                images: ['bottom'],
                src: '/images/illus/success.png',
                is_mobile: null,
                from: {
                    margin: '-100px 0px',
                    opacity: 0,
                },
                style: {
                    margin: '-30px 0px',
                    opacity: 0.5,
                },
                sub_filters: [
                    { states: ['error'], src: '/images/illus/error.png' }
                ]
            },
            // ------------------------------------ ORDERS
            {
                routes: ['orders'],
                images: ['left'],
                states: ['contains'],
                src: '/images/illus/bicycle.png',
                from: {
                    margin: '0px -150px',
                    opacity: 0,
                },
                style: {
                    margin: '0px -100px',
                    opacity: 1,
                },
            },
            {
                routes: ['orders'],
                images: ['bottom'],
                states: ['empty'],
                is_mobile: null,
                src: '/images/illus/shrug.png',
                from: {
                    margin: '-150px 0px',
                    opacity: 0,
                },
                style: {
                    margin: '-100px 0px',
                    opacity: 0.5,
                },
            },
            // ------------------------------------ CONTACTS
            {
                routes: ['contacts'],
                images: ['left'],
                src: '/images/illus/mail.png',
                from: {
                    margin: '0px -200px',
                    opacity: 0,
                },
                style: {
                    margin: '0px -150px',
                    opacity: 0.5,
                },
            },
            // ------------------------------------ RESERVATION
            {
                routes: ['reservation'],
                images: ['bottom'],
                src: '/images/illus/key.png',
                from: {
                    margin: '-200px 0px',
                    opacity: 0,
                    transform: 'rotateY(180deg) rotateZ(45deg)'
                },
                style: {
                    margin: '-70px 0px',
                    opacity: 0.5,
                    transform: 'rotateY(180deg) rotateZ(90deg)'
                },
            },
            // ------------------------------------ HOME BASE STYLE
            {
                routes: ['home'],
                images: ['left', 'right', 'bottom'],
                states: ['filters', 'areas', 'products', 'show_infos'],
                style: {
                    margin: '0px 30px',
                    opacity: 1,
                },
                sub_filters: [
                    // ---------------------------- MOBILE
                    {
                        images: ['bottom'],
                        is_mobile: true,
                        states: ['filters'],
                        src: '/images/illus/bicycle.png',
                        from: {
                            margin: '-50px -200px',
                            opacity: 0
                        },
                        style: {
                            margin: '-50px 0px',
                            opacity: 0.3
                        },
                    },
                    // ---------------------------- SHOW INFOS
                    {
                        states: ['show_infos'],
                        images: ['left'],
                        src: '/images/illus/bicycle.png',
                        from: {
                            margin: '0px -150px',
                            opacity: 0
                        },
                        style: {
                            margin: '0px -100px',
                            opacity: 0.5
                        },
                    },
                    // ---------------------------- HOME SRC
                    { states: ['filters'], images: ['left'], src: '/images/illus/bicycle.png', },
                    {
                        states: ['filters'], images: ['right'], src: '/images/illus/buildings.png',
                        from: {
                            margin: '0px -00px',
                            opacity: 0
                        },
                    },
                    {
                        states: ['areas'],
                        style: {
                            margin: '0px -50px',
                            opacity: 0.6,
                        },
                    },
                    {
                        states: ['products'],
                        style: {
                            margin: '0px -100px',
                            opacity: 0.3,
                        },
                    },
                ]
            },
        ]
    }),
    // -----------------------------------------------------
    watch: {
        /*
        @desc Recompute engine data on route change
        */
        $route() {
            setTimeout(() => {
                this.route = this.$router.history.current.name
                this.compute_image_data()
            }, 200)
        },
        /*
        @desc Recompute engine data on page state change
        */
        'state': {
            handler: function () {
                this.compute_image_data()
            },
            immediate: true
        },
        /*
        @desc Recompute engine data on device type
        */
        '$is_mobile'() {
            this.compute_image_data()
        }
    },
    computed: {
        ...mapState({ state: 'back_state' }),
    },
    methods: {
        // ----------------------------------------------------- COMPUTER
        /*
        @desc Main Illustration data computer
        */
        compute_image_data() {
            const is_mobile = this.$is_mobile
            clearTimeout(this.to)
            this.to = setTimeout(() => {
                const images = Object.fromEntries(this.image_types.map(image_name => {
                    const find_config_amongst = (possibilities) => {
                        if (!possibilities) return {}
                        const found_configs = possibilities
                            .filter(c =>
                                (c.routes?.includes(this.route) ?? true) &&
                                (c.images?.includes(image_name) ?? true) &&
                                (!c.states || (this.state && c.states.includes(this.state)))
                            )
                        const merged_main_config = JSON.parse(JSON.stringify(found_configs.reduce((a, b) => ({
                            ...a, ...b,
                            sub_filters: (a.sub_filters ?? []).concat(b.sub_filters ?? [])
                        }), {}) ?? {}))
                        const sub_filters = merged_main_config.sub_filters
                        delete merged_main_config.sub_filters
                        const final_config = { ...merged_main_config, ...find_config_amongst(sub_filters) }
                        return final_config
                    }
                    const given_config = find_config_amongst(this.images)
                    const current_config =
                        given_config.is_mobile === null || (is_mobile == !!given_config.is_mobile)
                            ?
                            given_config : {}
                    const style = current_config.style ?? {}
                    const image_data = {
                        ...current_config,
                        style,
                        width: style?.width ?? '300'
                    }

                    const from = current_config.from
                    if (from) {
                        this.$set(image_data, 'style', {})
                        const elm = document.getElementById('back_img_' + image_name)
                        elm.classList.remove('transitioner')
                        setTimeout(() => {
                            Object.keys(from).forEach(name => {
                                elm.style[name] = from[name]
                            })
                        })
                        setTimeout(() => {
                            elm.classList.add('transitioner')
                        }, 200)
                        setTimeout(() => {
                            this.$set(image_data, 'style', style)
                        }, 200)
                    }

                    return [image_name, image_data]
                }))
                this.$set(this, 'current_image_data', images)
            })
        }
    },
    mounted() {
        setTimeout(() => {
            this.route = this.$router.history.current.name
            this.compute_image_data()
        }, 200)
    }
}
</script>

<style scoped>
.back-illus {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
.transitioner {
    transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
}
.main_transform {
    position: absolute;
    bottom: 40%;
    width: 512px;
    height: 512px;
}
.back_img {
    position: absolute;
    opacity: 0;
    margin: 0px 0px;
}
.left_img,
.right_img {
    top: 50%;
}
.transform_left,
.left_img {
    left: 0px;
}
.transform_right,
.right_img {
    right: 0px;
}
.transform_bottom {
    bottom: 0px;
    left: 50%;
}
.bottom_img {
    bottom: 0px;
    left: -30%;
}
</style>