<template>
    <div>
        <v-container class='cart_container'>
            <span
                class='watermark'
                v-if="cart_count == 0"
            >
                <h1>{{$t('cart.emptycart')}}</h1>
                <v-btn
                    to="/"
                    class='mt-10'
                    color='primary'
                    fab
                    large
                    elevation="3"
                >
                    <v-icon>mdi-home</v-icon>
                </v-btn>
            </span>
            <template v-else>
                <v-card
                    :disabled="validating"
                    class='mb-3'
                    v-for="(agreg,timer) in agregated_cart"
                    :key="timer"
                >
                    <v-card-title>
                        <span v-if="!$is_mobile">
                            {{agreg.start}}
                            <v-icon
                                disabled
                                class='mr-3 ml-3'
                                style='margin-top:-2px'
                            >mdi-arrow-right</v-icon>
                            {{agreg.end}}
                        </span>
                        <span
                            v-else
                            style="font-size:14px"
                        >{{agreg.start}}</span>
                        <v-spacer></v-spacer>
                        <v-icon
                            v-if="$is_mobile"
                            small
                            disabled
                        >mdi-arrow-right</v-icon>
                        <v-spacer></v-spacer>
                        <span v-if="!$is_mobile">{{$t('cart.duration')}} - {{agreg.span}}</span>
                        <span
                            v-else
                            style="font-size:14px"
                        >{{agreg.end}}</span>

                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col
                                v-for="(item,index) in agreg.items"
                                :key="item.id"
                                :sm="agreg.items.length > 1 && (index != agreg.items.length-1 || agreg.items.length%2==0) ? 6 : 12"
                                cols="12"
                            >
                                <product
                                    class='mb-3 product'
                                    :selected="true"
                                    :closable="true"
                                    :product="item.product"
                                    @close="item_to_remove=item.id;remove_dialog=true"
                                ></product>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <table
                    class='total mt-10 mb-10'
                    cellpadding="0"
                    cellspacing="0"
                >
                    <tr>
                        <td
                            v-if="!$is_mobile"
                            style=" width:50%"
                        >
                        </td>
                        <td
                            class='line'
                            style='text-align:left'
                        >{{$t('cart.total')}}</td>
                        <td
                            class='line'
                            style="width:10%"
                        ></td>
                        <td class='line'>{{card_price}} €</td>
                    </tr>
                </table>
                <v-alert
                    type="error"
                    v-if="validate_error"
                >
                    {{validate_error}}
                </v-alert>
                <v-row>
                    <v-col>
                        <v-checkbox
                            v-model="accept_cgu"
                            class='conditions'
                        >
                            <template slot="label">
                                {{$t('cart.acceptcgu1')}}<router-link
                                    to="/cgu#cgu"
                                    @click.stop
                                >{{$t('cart.acceptcgu2')}}</router-link>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col cols="3">
                        <v-btn
                            color='primary'
                            block
                            large
                            text
                            @click="clear_cart"
                            :disabled="validating"
                        >
                            <v-icon
                                icon
                                class='mr-3'
                            >mdi-cart-off</v-icon> supprimer le panier
                        </v-btn>
                    </v-col> -->
                    <v-col>
                        <v-btn
                            color='primary'
                            block
                            large
                            @click="validate"
                            :disabled="validating || !accept_cgu"
                            :loading="validating"
                        >
                            <v-icon class='mr-3'>mdi-credit-card</v-icon>
                          {{$t('validate')}}
                            <template v-if="!is_connected"> ({{$t('connect')}})</template>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-container>

        <v-dialog
            v-model="remove_dialog"
            max-width="400"
        >
            <v-card v-if="item_to_remove">
                <v-card-title class="text-h5">
                    {{$t('cart.deletefromcart')}}
                </v-card-title>

                <v-card-text>
                    <product
                        :error="true"
                        color="error"
                        :product="cart_map[item_to_remove].product"
                    ></product>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="item_to_remove=null;remove_dialog = false"
                    >
                        {{$t('cancel')}}
                    </v-btn>

                    <v-btn
                        color="error"
                        text
                        @click="remove_cart_item(item_to_remove);item_to_remove=null;remove_dialog = false"
                    >
                        {{$t('cart.delete')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="want_to_validate"
            max-width="400"
        >
            <v-card class='pa-5'>
                <v-btn
                    icon
                    absolute
                    top
                    right
                    @click="want_to_validate=false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <connector @connected="want_to_validate=false,validate()"></connector>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { format } from 'date-fns'
import product from '../components/product.vue'
import { v4 as uuid } from 'uuid'
import Connector from '../components/connector.vue'

/*
@desc Cart page
*/
export default {
    components: { product, Connector },
    data: () => ({
        remove_dialog: false,
        item_to_remove: null,
        validating: false,
        want_to_validate: false,
        validate_error: null,
        accept_cgu: false,
    }),
    watch: {
        /*
        @desc watch main store cart for changes
        */
        cart: {
            handler() {
                this.set_back_state(this.cart.length ? 'contains' : 'empty')
            },
            immediate: true,
            deep: true,
        }
    },
    computed: {
        ...mapState(['cart']),
        ...mapGetters(['cart_count', 'cart_map', 'card_price', 'is_connected']),
        /*
        @desc group cart items by reservation time range
        */
        agregated_cart() {
            return this.cart.reduce((agreg, item) => {
                const timer = item.start_date + '' + item.end_date
                if (!agreg[timer]) agreg[timer] = {
                    start: format(new Date(item.start_date * 1000), 'dd/MM/yyyy - HH:mm'),
                    end: format(new Date(item.end_date * 1000), 'dd/MM/yyyy - HH:mm'),
                    span: this.$utils.spend(item.start_date * 1000, item.end_date * 1000),
                    items: []
                }
                agreg[timer].items.push(item)
                return agreg
            }, {})
        }
    },
    methods: {
        ...mapActions(['remove_cart_item', 'clear_cart', 'set_cart_uuid', 'set_back_state']),
        /*
        @desc triggers payment process for cart content
        */
        async validate() {
            this.validate_error = null
            if (!this.is_connected) {
                return this.want_to_validate = true
            }
            const items = this.cart
                .map(({ start_date, end_date, product_id }) => ({
                    startDate: parseInt(start_date),
                    endDate: parseInt(end_date),
                    productId: product_id
                }))
            const order_uuid = uuid()
            const origin = location.origin
            const success_path = '/order?result=success&order=' + order_uuid
            const successUrl = origin + success_path
            const cancelUrl = origin + '/cart'
            this.set_cart_uuid(order_uuid)
            const order = {
                uuid: order_uuid,
                successUrl,
                cancelUrl,
                items
            }
            this.validating = true
            try {
                const payment_data = await this.$api.user.booking.order(order)
                const { paymentUrl, status } = payment_data
                if (!paymentUrl) {
                    if (status == 'OK') this.$router.push(success_path)
                    else {
                        if (status == 'ALREADY_USED') {
                            return await this.validate()
                        }
                        this.validate_error = {
                            'ALREADY_BOOKED': this.$t('cart.alreadybooked'),
                        }[status]
                    }
                }
                else {
                    location = paymentUrl
                }
            } catch (e) {
                console.error(e)
                this.validate_error = this.$t('cart.validateerror')
            }
            this.validating = false
        }
    },
}
</script>

<style>
.conditions .v-label {
    display: block !important;
}
.watermark {
    position: fixed;
    width: 100%;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    left: 0px;
}
.watermark h1 {
    font-size: 40px;
    opacity: 0.3;
    pointer-events: none;
}

.card_item-enter-active,
.card_item-leave-active {
    transition: all 0.2s ease;
}
.card_item-enter-from,
.card_item-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.total {
    width: 100%;
    text-align: right;
    font-size: 24px;
    font-weight: 500;
}

.total .line {
    height: 50px;
    border-top: 1px solid #aaa;
}
.cart_container {
    max-width: 100%;
    width: 1000px !important;
}
</style>