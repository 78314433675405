<template>
  <v-card
      :class="!available ? 'disable' : ''"
      :disabled="disabled || !available"
      @click="$emit('click')"
      :href="href"
      :to="to"
      :target="target"
      selectable
      :elevation="elevation ? elevation : available ? 10 : 1"
      height="100%"
      :dark='error || selected'
      :color='error ? "error" : selected ? color ? color : "secondary" : ""'
  >
    <v-img
        v-if="!$is_mobile"
        :class='[disabled ? "disabled" : "",selected ? "selected" : "" ,"back_img"]'
        :src="product.imageUrl"
        max-width="150px"
        width="20%"
        height="100%"
    />
    <div :style="'position:relative;z-index:1;padding-left:'+ ($is_mobile ? '0px' : 'min(20%, 150px)')">
      <v-card-title :style="'width: calc(100% '+(closable ? '- 24px':'')+')'">
        {{product.label}}
      </v-card-title>
      <v-card-title
          class='desc'
          v-if="!dense"
      >
        <v-card-subtitle v-if="available">
          {{product.description}}
        </v-card-subtitle>
      </v-card-title>
      <v-btn
          v-if="closable"
          icon
          :color="closeIconColor"
          class='close_icon'
          @click="$emit('close')"
      >
        <v-icon>mdi-{{closeIcon ? closeIcon : 'close'}}</v-icon>
      </v-btn>
      <v-btn
          v-if="selectable"
          icon
          :color="closeIconColor"
          class='close_icon'
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>

    <v-img
        v-if="$is_mobile && !dense"
        :src="product.imageUrl"
        width="50%"
        height="130px"
    />

    <v-card-title v-if="!dense">
      <div
          :class='["price",selected ? "selected" : ""]'
          :style="{background: $vuetify.theme.themes[theme].price, color:$vuetify.theme.themes[theme].footer}"
      >
        {{price_text ? price_text : product.price/100+$t('product.pricetxt')}}
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
/*
@desc Product card display
*/
export default {
  props: [
    'product', 'selected', 'closable', 'error', 'color',
    'href', 'target', 'elevation', 'to', 'dense', "close-icon",
    'close-icon-color', 'disabled', 'price_text','selectable'
  ],
  computed: {
    available() {
      return this.product.available !== false && this.product.activated !== false
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
  },
}
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}
.desc {
  padding: 0px 20px 0px 0px !important;
  margin-top: -10px;
}
.back_img {
  position: absolute;
  z-index: 0;
  opacity: 0.9;
}
.back_img.selected {
  opacity: 0.8;
}
.price {
  position: absolute;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  bottom: 10px !important;
  right: 10px !important;
  background: #fff;
  padding: 5px 10px;
  border-radius: 100px;
}
.price.selected {
  background: transparent;
}
.disabled {
  opacity: 0.5;
  filter: grayscale();
}
.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>