<template>
    <v-dialog
        v-model="open_dialog"
        max-width="400"
    >
        <v-card
            v-if="object"
            class='pa-5'
        >
            <slot></slot>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="$emit('cancel',object);open_dialog=false"
                >
                    {{cancel_text ? cancel_text : $t('cancel')}}
                </v-btn>
                <v-btn
                    color="error"
                    text
                    @click="$emit('ok',object);open_dialog=false"
                >
                    {{ok_text ? ok_text : $t('validate')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['ok_text', 'cancel_text', 'object'],
    data: () => ({
        open_dialog: false,
    }),
    watch: {
        object() {
            this.open_dialog = !!this.object
        }
    }

}
</script>

<style>
</style>