<template>
    <v-container
        class='order_result_container'
        style='text-align:center'
    >
        <v-alert :type="is_success ? 'success' : 'error'">
            {{is_success ? $t('order.ordersuccess') : $t('order.orderfailure')}}
        </v-alert>
        <v-btn
            :to="is_success ? ('/orders?order_id='+order_id) : '/cart'"
            class='mt-10'
            color='primary'
            rounded
            large
            elevation="3"
        >
            <v-icon class='mr-3'>mdi-{{!is_success ? 'cart' : 'playlist-check'}}</v-icon>
            {{!is_success ? $t('order.backtocart') : order_id ? $t('order.seebooking') : $t('order.gotobookings')}}
        </v-btn>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'
/*
@desc Order result page (only information page)
*/
export default {
    computed: {
        /*
        @desc Result state from url args
        */
        result() {
            return this.$utils.get_url_args().result
        },
        is_success() {
            return this.result == 'success'
        },
        /*
        @desc Order uuid from url args
        */
        order_uuid() {
            return this.$utils.get_url_args().order
        },
        /*
        @desc Order final ID from url args
        */
        order_id() {
            return this.$utils.get_url_args().order_id
        }
    },
    methods: {
        ...mapActions(['purge_cart_from', 'set_back_state'])
    },
    /*
    @desc Purge cart from newly aquired items (identified by their uuid related to the success order uuid)
    */
    mounted() {
        this.set_back_state(this.is_success ? 'success' : 'error')
        if (this.is_success) {
            this.purge_cart_from(this.order_uuid)
        }
    }
}
</script>

<style>
.order_result_container {
    max-width: 100%;
    width: 1000px !important;
}
</style>
