<template>
    <v-container>
        <template v-if="loaded">
            <v-row>
                <v-col
                    cols="2"
                    v-if="!$is_mobile"
                >
                    <v-navigation-drawer
                        permanent
                        fixed
                        style="top:150px;z-index:1"
                    >
                        <v-card-title>{{$t('terms')}}</v-card-title>
                        <v-divider></v-divider>
                        <v-card
                            v-for="
                        (legal,index)
                        in
                        legals"
                            :key="legal.md"
                            class='pa-5 ma-3'
                            elevation="0"
                            :color="index == navigation ? 'secondary':''"
                            :dark="index == navigation"
                            @click="go_to(legal)"
                        > {{legal.name}}
                        </v-card>
                    </v-navigation-drawer>
                </v-col>
                <v-col>
                    <div
                        v-for="legal in legals"
                        :key="legal.md"
                        class='legal'
                    >
                        <div
                            class='legal_ref'
                            :ref="legal.md"
                        ></div>
                        <u>
                            <h1 class='main mb-5'>{{legal.name}}</h1>
                        </u>
                        <div
                            class='markdown-body'
                            v-html="md_htmls[legal.md]"
                        ></div>
                    </div>
                </v-col>
            </v-row>

            <v-bottom-navigation
                v-if="$is_mobile"
                color="secondary"
                fixed
                bottom
                v-model="navigation"
            >
                <v-btn
                    v-for="legal in legals"
                    :key="legal.md"
                    @click="go_to(legal)"
                >
                    {{legal.name}}
                </v-btn>
            </v-bottom-navigation>
        </template>
        <v-container
            v-else
            class='mt-10 text-center'
        >
            <v-progress-circular
                indeterminate
                color="primary"
            >
            </v-progress-circular>
        </v-container>
    </v-container>
</template>

<script>
import { marked } from 'marked'
/*
@desc CGU page - loading CGU markdown content from /cgu/---.md files
*/
export default {
    name: 'Home',
    data: () => ({
        navigation: 0,
        loaded: false,
        md_htmls: {},
    }),
    methods: {
        /*
        @desc Go to specific legal text
        */
        go_to(legal) {
            this.legal_target(legal).scrollIntoView({ behavior: "smooth" })
        },
        /*
        @desc Get legal text DOM element
        */
        legal_target(legal) {
            return this.$refs[legal.md]?.[0]
        },
        /*
        @desc Check if element is in viewport
        */
        isInViewport(el) {
            if (!el) return false
            return el?.getBoundingClientRect().y < 100
        },
        /*
        @desc Triggered when page scrolled
        */
        scrolled() {
            let next_nav_i = 0
            this.legals.forEach((legal, i) => {
                if (this.isInViewport(this.legal_target(legal))) {
                    next_nav_i = i
                }
            })
            if (next_nav_i != this.navigation) {
                this.navigation = next_nav_i
            }
        },
    },
    computed: {
      legals() {
        return [
          { name: this.$t('cgu.general'), md: 'general' },
          { name: this.$t('cgu.cgu'), md: 'cgu' },
          { name: this.$t('cgu.cgu_station'), md: 'cgu_station'},
          { name: this.$t('cgu.privacy'), md: 'confidentialite' },
          //{ name: this.$t('cgu.cookies'), md: 'cookie' },
        ]
      }
    },
    /*
    @desc Init scroll listener + parse legal texts (load mds and parse to HTML)
    */
    mounted() {
        document.addEventListener('scroll', this.scrolled)
        this.legals.forEach(async (legal, i) => {
            const md_data = await (await fetch('/cgu/' + legal.md + '.md')).text()
            const md_html = marked.parse(md_data)
            legal.i = i
            this.$set(this.md_htmls, legal.md, md_html)
            if (Object.keys(this.md_htmls).length == this.legals.length)
                this.loaded = true
        })
    },
    destroyed() {
        document.removeEventListener('scroll', this.scrolled)
    },
}
</script>

<style>
.main {
    font-family: "Playfair Display", arial, helvetica;
    font-size: 40px;
}
.legal_ref {
    padding-bottom: 150px;
    margin-top: -150px;
}
.mobile .legal_ref {
    padding-bottom: 70px;
    margin-top: -70px;
}
.legal {
    background: #fff;
    padding: 20px;
    margin-bottom: 100px;
}
.legal .markdown-body {
    padding-left: 30px;
}
.mobile .legal .markdown-body {
    padding-left: 0px;
}
.markdown-body h1 {
    font-size: 25px !important;
}
.markdown-body h2 {
    font-size: 20px !important;
}
</style>
