<template>
    <v-container class='reset_container'>
        <template v-if="!is_reseting">
            <v-card
                elevation="0"
                v-if="!sent"
                :disabled="sending"
            >
                <v-card-title>{{$t('pass_reset.title')}}</v-card-title>
                <v-card-subtitle>
                  {{$t('pass_reset.instructions')}}</v-card-subtitle>
                <v-form v-model="form_valid">
                    <v-text-field
                        outlined
                        :label="$t('email')"
                        v-model="email"
                        :rules="[email_verify]"
                    >
                    </v-text-field>
                </v-form>
                <v-btn
                    block
                    large
                    color='primary'
                    @click="send(email)"
                    :disabled="sending || !form_valid"
                    :loading="sending"
                >{{$t('pass_reset.reset')}}</v-btn>
            </v-card>
            <div
                v-else
                style='text-align:center'
            >
                <v-alert type='success'>{{$t('pass_reset.sentmail')}}</v-alert>
                <v-btn
                    to="/"
                    class='mt-10'
                    color='primary'
                    fab
                    large
                    elevation="3"
                >
                    <v-icon>mdi-home</v-icon>
                </v-btn>
            </div>
        </template>
        <template v-else>
            <v-card
                elevation="0"
                :disabled="sending"
                v-if="!sent"
            >
                <v-card-title>{{$t('pass_reset.instructions2')}}</v-card-title>
                <v-form v-model="form_valid">
                    <v-text-field
                        outlined
                        :label="$t('password')"
                        type='password'
                        :rules="[rules.password]"
                        v-model="password"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined
                        :label="$t('confirmpassword')"
                        type='password'
                        v-model="confirmPassword"
                        :rules="[confirm_verify]"
                    >
                    </v-text-field>
                </v-form>
                <v-btn
                    block
                    large
                    color='primary'
                    @click="reset"
                    :disabled="sending || !form_valid"
                    :loading="sending"
                >{{$t('validate')}}</v-btn>
            </v-card>
            <div
                v-else
                style='text-align:center'
            >
                <v-alert type='success'>{{$t('pass_reset.success')}}</v-alert>
                <v-btn
                    to="/connect"
                    class='mt-10'
                    color='primary'
                    fab
                    large
                    elevation="3"
                >
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </div>
        </template>
    </v-container>
</template>

<script>
/*
@desc Password Reset page
*/
export default {
    data: () => ({
        sent: false,
        sending: false,

        form_valid: false,

        email: '',


        email_verify: v => v.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g) || 'E-mail incorrect',

        password: '',
        confirmPassword: ''
    }),
    computed: {
        /*
        @desc Url argument `key`
        */
        key() {
            const args = Object.fromEntries(location.href.split('?').pop().split('&').map(elm => elm.split('=')))
            return args.key
        },
        /*
        @desc Check if page was opened for reseting or to ask a new password
        */
        is_reseting() {
            return !!this.key
        },
        /*
        @desc Password confirm verifier
        */
        confirm_verify() {
            return this.confirmPassword == this.password || this.$t('differentpasswords')
        },

        rules() {
          return {
            required: v => !!v || this.$t('requiredfield'),
            password: v => {
              v ??= ''
              const mins = !!v.match(/[a-z]+/)
              const maj = !!v.match(/[A-Z]+/)
              const nums = !!v.match(/[0-9]+/)
              const height = v.length >= 8
              return (maj && mins && nums && height) || this.$t('requiredpassword')
            }
          }
        },
    },
    methods: {
        /*
        @desc Send password reset ask
        */
        async send(email) {
            this.sending = true
            const rec = await this.$api.auth.password.ask_reset(email)
            this.sent = true
            this.sending = false
        },
        /*
        @desc Send ask for reset using comp data password
        */
        async reset() {
            this.sending = true
            const rec = await this.$api.auth.password.reset({ key: this.key, newPassword: this.password })
            this.sent = true
            this.sending = false
        }
    },
}
</script>

<style>
.reset_container {
    max-width: 100%;
    width: 1000px !important;
}
</style>