<template>
  <div>
    <h1>Comment ça marche ?</h1>
    <v-img
        v-if="!$is_mobile"
        :src=tutorialImg
    />
    <v-img
        v-if="$is_mobile"
        :src=tutorialMobileImg
        class="mobile-image"
    />
  </div>
</template>

<script>

/*
@desc Main Home page (booking search, wrapper for `product-searcher` and `home-infos` component)
*/
export default {
  name: 'Tutorial',
  data: () => ({
    tutorialImg:"",
    tutorialMobileImg:"",
  }),
  mounted() {
    if (localStorage.tutorialImg) {
      this.tutorialImg = localStorage.tutorialImg;
    }
    else {
      this.tutorialImg = "/images/HowTo.png"
      localStorage.tutorialImg = "/images/HowTo.png"
    }

    if (localStorage.tutorialMobileImg) {
      this.tutorialMobileImg = localStorage.tutorialMobileImg;
    }
    else {
      this.tutorialMobileImg = "/images/HowToMobile.png"
      localStorage.tutorialMobileImg = "/images/HowToMobile.png"
    }
  }
}
</script>

<style>
h1 {
  font-family: Roboto,"Playfair Display", arial, helvetica;
  overflow: hidden;
  font-size:2vw !important;
  transition: all 0.5s;
  text-align: center;
  margin-bottom: 30px !important;
  margin-top: 20px !important;
}

.mobile h1 {
  margin-top: 30px;
  font-size:6vw !important;
  height: 50px;
}

.mobile-image {
  width: 300px !important; /* Largeur de base de l'image */
  height: auto; /* Maintenir le ratio d'aspect */
  display: block; /* Pour s'assurer que les marges auto fonctionnent */
  margin: 0 auto; /* Centrer l'image horizontalement */
  image-rendering: crisp-edges; /* Améliorer la qualité de rendu */
}
</style>