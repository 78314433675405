<template>
    <span>
        <v-btn
            v-if="!is_connected"
            icon
            color='primary'
            to='/connect'
            :loading="connecting"
            :disabled="connecting"
        >
            <v-icon>mdi-account</v-icon>
        </v-btn>

        <v-btn
            class='mt-1 user_name'
            text
            v-else
            to="/account"
            color="primary"
        >
            <v-icon left>mdi-{{is_admin ? 'shield-account' : 'account'}}</v-icon>
            <span class='first_name'>{{user.firstName}}</span>
            <span v-if="!$is_mobile">{{user.lastName}}</span>
        </v-btn>

    </span>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
/*
@desc user connection status & access top button
*/
export default {
    data: () => ({
        connecting: false,
    }),
    computed: {
        ...mapGetters(['is_connected', 'is_admin']),
        ...mapState(['user']),
    },
    methods: {
        ...mapActions(['reconnect']),
    },
    /*
    @desc Auto check user status
    */
    async mounted() {
        this.connecting = true
        try {
            if (localStorage.getItem('account-token') ?? sessionStorage.getItem('account-token')) {
                await this.reconnect(this.$api.auth.get)
            }
        } catch (e) {
            console.error('cound not connect', e)
        }
        this.connecting = false
    }
}
</script>

<style>
.user_name {
    text-transform: none !important;
}
.mobile .first_name {
    max-width: 120px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.2em;
    white-space: nowrap;
}
</style>