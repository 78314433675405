<template>
    <v-container class='account_container'>
        <v-row v-if="!need_to_connect">
            <v-col :cols="$is_mobile ? 12 : 4">
                <v-card>
                    <user-card
                        v-if="is_connected"
                        :user="user"
                    ></user-card>
                    <v-card-text>
                        <v-card
                            v-for="panel in filtered_panels"
                            v-show="!panel.invisible"
                            :key="panel.state"
                            class='pa-3'
                            elevation="0"
                            :color="panel_state.state == panel.state ? 'secondary':''"
                            :dark="panel_state.state == panel.state"
                            @click="panel.href ? null : panel_state=panel"
                            :href="panel.href ? panel.href : null"
                            :target="panel.href ? '_blank' : null"
                        >
                            <v-icon class='mr-3'>mdi-{{panel.icon}}</v-icon> {{panel.name}}
                        </v-card>
                        <v-btn
                            class='mt-3'
                            color="error"
                            text
                            @click="discon_local"
                        >
                          {{$t('account.signout')}}
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-if="panel_id">
                <v-card-title
                    style="font-size:25px"
                    v-if="panel_state.name"
                >{{panel_state.name}}</v-card-title>
                <!-- ----------------------------------------------------------------------- WELCOME -->
                <template v-if="panel_id=='account'">
                    <h1>{{$t('account.welcome')}}</h1>
                    <h3 style="opacity:0.5">{{$t('account.explaination')}}</h3>
                </template>
                <!-- ----------------------------------------------------------------------- INFOS -->
                <template v-else-if="panel_id=='infos'">
                    <v-card>
                        <v-card-text>
                            <template v-if="local_user">
                                <v-text-field
                                    v-for="(info,prop) in user_form"
                                    v-show="!info.invisible"
                                    :key="prop"
                                    :disabled="info.locked"
                                    v-model="local_user[prop]"
                                    :label="info.name"
                                    @keydown="upd_success_message=null"
                                >
                                </v-text-field>

                                <v-menu v-model="languageMenu" offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="pa-5 mr-5" v-bind="attrs" v-on="on" text plain>
                                      <v-icon left>mdi-translate</v-icon>
                                      {{ activeLang }}
                                      <v-icon small right>mdi-menu-down</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list dense v-model="$i18n.locale">
                                    <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
                                      <v-list-item-title>{{ lang.title }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>

                                <v-alert
                                    type="success"
                                    v-if="upd_success_message"
                                >{{upd_success_message}}</v-alert>
                                <v-btn
                                    v-if="user_needs_save"
                                    color="primary"
                                    @click="save_changes"
                                >
                                  {{$t('account.save')}}
                                </v-btn>
                            </template>

                            <v-skeleton-loader
                                v-else
                                class="mx-auto"
                                max-width="300"
                                type="list-item@4"
                            ></v-skeleton-loader>
                        </v-card-text>
                    </v-card>
                </template>
                <!-- ----------------------------------------------------------------------- MOT DE PASSE -->
                <template v-else-if="panel_id=='password'">
                    <v-card>
                        <v-card-text>
                            <v-form
                                ref="pass_reset"
                                v-model="upd_form_is_valid"
                            >
                                <v-text-field
                                    v-for="(info,name) in pass_form"
                                    @keydown="upd_pass_error=null;upd_pass_success=null"
                                    :key="name"
                                    :label="name"
                                    :rules="[
                                    info.required !== false ? rules.required:null, 
                                    info.link=='confirm_password' ? passwordConfirmationRule : null,
                                    info.link=='password' ? rules.password : null,
                                    info.link=='email' ? rules.email : null].filter(e=>e)"
                                    :type="info.disp ? '' : info.type"
                                    :append-icon="info.type == 'password' ? info.disp ? 'mdi-eye' : 'mdi-eye-off' : ''"
                                    @click:append="info.disp = !info.disp"
                                    v-model="upd_pass[info.link]"
                                ></v-text-field>
                            </v-form>
                            <v-alert
                                v-if="upd_pass_error || upd_pass_success"
                                :type="upd_pass_success ? 'success' : 'error'"
                            >{{upd_pass_error || upd_pass_success}}</v-alert>
                            <v-btn
                                color="primary"
                                :disabled="!upd_form_is_valid"
                                @click="save_password"
                            >
                              {{$t('account.save')}}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </template>
                <!-- ----------------------------------------------------------------------- ADMIN -->
                <admin-orgas v-else-if="panel_id=='admin'"></admin-orgas>
            </v-col>
        </v-row>

        <!-- ----------------------------------------------------------------------- CONNECT DIALOG -->

        <v-dialog
            v-model="need_to_connect"
            max-width="400"
        >
            <v-card class='pa-5'>
                <v-btn
                    icon
                    absolute
                    top
                    right
                    @click="need_to_connect=false;"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <connector></connector>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Connector from '../components/connector.vue'
import AdminOrgas from '../components/admin-orgas.vue'
import userCard from '../components/user-card.vue'
/*
@desc Display user account data & managment, redirect to connect if user is not connected
*/
export default {
    components: { Connector, AdminOrgas, userCard },
    data: () => ({
        need_to_connect: false,
        local_user: null,
        stripe: null,
        panel_state: {},
        upd_pass: {
            current_password: '',
            password: '',
            confirm_password: '',
        },
        upd_pass_error: null,
        upd_pass_success: null,
        upd_form_is_valid: false,
        upd_success_message: null,
        languageMenu: false,
        langs: [
          { title: 'English', value: 'en' },
          { title: 'Français', value: 'fr' }
        ],
    }),
    watch: {
        /*
        @desc if need connect is true, re-route to /connect
        */
        need_to_connect() {
            if (this.need_to_connect === true) {
                this.$router.push('/connect')
            }
        },
        is_connected() {
            this.need_to_connect = this.is_connected === false
            this.local_user = JSON.parse(JSON.stringify(this.user))
        },
        panel_state() {
            this.set_back_state(this.panel_state.state)
        }
    },
    computed: {
        ...mapGetters(['is_connected', 'is_admin']),
        ...mapState(['user', 'connecting']),
        passwordConfirmationRule() {
            return () => (this.upd_pass.password === this.upd_pass.confirm_password) || this.$t('differentpasswords')
        },
        /*
        @desc current selected managment panel ID
        */
        panel_id() {
            return this.panel_state?.state
        },
        user_needs_save() {
            const local = { ...this.user, ...this.local_user }
            const remote = { ...this.user }
            delete local.lastModifiedDate
            delete remote.lastModifiedDate
            return JSON.stringify(local) != JSON.stringify(remote)
        },
        /*
        @desc managment panels are filterd depending on user ROLES (admin)
        */
        filtered_panels() {
            return this.panels.filter(p => !p.is_admin || this.is_admin)
        },
        panels() {
          return [
            { state: 'infos', name: this.$t('account.myinfo'), icon: 'account', invisible: false },
            { state: 'password', name: this.$t('account.newpassword'), icon: 'key', invisible: false },
            { state: 'adresse', name: this.$t('account.myaddresses'), icon: 'clipboard', href: null, invisible: true },
            { state: 'admin', name: this.$t('account.admin'), icon: 'shield-account', is_admin: true, invisible: false }
          ]
        },
        pass_form() {
          return {
            [this.$t('account.actualpassword')]: {type: 'password', link: 'current_password', disp: false},
            [this.$t('account.newpassword')]: {type: 'password', link: 'password', disp: false},
            [this.$t('account.newpasswordconfirm')]: {type: 'password', link: 'confirm_password', disp: false},
          }
        },
        user_form() {
          return {
            email: {
              name: this.$t('email'),
              locked: true,
              invisible: false
            },
            firstName: {
              name: this.$t('firstname'),
              invisible: false
            },
            lastName: {
              name: this.$t('lastname'),
              invisible: false
            },
            langKey: {
              name: this.$t('language'),
              locked: true,
              invisible: true
            },
            /*phone: {
              name: this.$t('phonenumber')
            },*/
          }
        },
        rules() {
          return {
            required: v => !!v || this.$t('requiredfield'),
            password: v => {
              v ??= ''
              const mins = !!v.match(/[a-z]+/)
              const maj = !!v.match(/[A-Z]+/)
              const nums = !!v.match(/[0-9]+/)
              const height = v.length >= 8
              return (maj && mins && nums && height) || this.$t('requiredpassword')
            }
          }
        },
    },
    methods: {
        ...mapActions(['disconnect', 'upduser', 'set_back_state']),
        /*
        @desc disconnect user
        */
        discon_local() {
            this.disconnect()
            this.$router.push('/connect')
        },
        async save_changes() {
            const local = { ...this.user, ...this.local_user }
            try {
                const upd = await this.$api.auth.update(local)
                this.upd_success_message = this.$t('account.modifsuccess')
            } catch (e) {
                console.error('update error', e)
            }
            this.upduser(this.$api.auth.get)
        },
        async save_password() {
            const newPassword = this.upd_pass.password
            const currentPassword = this.upd_pass.current_password
            const dto = { currentPassword, newPassword }
            try {
                const upd = await this.$api.auth.update_password(dto)
                console.log(upd)
                this.upd_pass_success = this.$t('account.modifsuccess')

                this.$refs.pass_reset.reset()
            } catch (e) {
                this.upd_pass_error = this.$t('account.passwordupdateerror')
                console.error('update error', e)
            }
            this.upduser(this.$api.auth.get)
        },
        handleMenuItemClick (lang) {
          this.activeLang = lang.title;
          this.local_user.langKey= lang.value

          if (this.$i18n.locale !== lang.value) {
            this.$i18n.locale = lang.value;
          }
        }
    },
    /*
    @desc check connection state + load stripe link
    */
    mounted() {
        setTimeout(() => {
            this.need_to_connect = this.is_connected === false
            this.local_user = JSON.parse(JSON.stringify(this.user))
        }, 1000)
        const back_url = location.origin + '/account'
        this.$api.user.booking.stripe_portal({ url: back_url }).then(({ url }) => {
            console.log(url)
            this.panels.find(p => p.state == 'adresse').href = url
        })
        this.panel_state = this.panels[0]
    }
}
</script>

<style>
/* .account_container {
    max-width: 100%;
    width: calc(100% - 300px) !important;
}
.mobile .account_container {
    width: auto;
} */
</style>