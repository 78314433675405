<template>
    <div style="display:flex;align-items:center">
        {{title}}
        <v-select
            class='ml-5'
            :items="full_select"
            v-model="selected"
            style="max-width:80px"
        >
        </v-select>
    </div>
</template>

<script>
export default {
    props: ['value', 'title', 'hours', 'minutes', 'last'],
    data: () => ({
        selected: '',
    }),
    watch: {
        hours() {
            if (this.hours[0] > this.selection_splitted[0])
                this.selected = this.hours[0] + ':' + this.$utils.add_zeros(this.minutes[0])
        },
        selected() {
            this.$emit('input', this.selection_splitted)
        }
    },
    computed: {
        selection_splitted() {
            return this.selected.split(':').map(e => parseInt(e))
        },
        full_select() {
            return this.hours.map((h, i) => {
                if (this.last && i == this.hours.length - 1) return h + ':00'
                return this.minutes.map(m => h + ':' + this.$utils.add_zeros(m))
            }).flat()
        }
    },
    mounted() {
        if (!this.value) {
            this.selected = this.hours[0] + ':' + this.$utils.add_zeros(this.minutes[0])
        } else {
            this.selected = this.value[0] + ':' + this.$utils.add_zeros(this.value[1])
        }
    }
}
</script>

<style>
</style>