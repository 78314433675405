<template>
    <v-card
        color="primary"
        dark
        elevation="0"
        :class='dense ? "pa-1" : "pa-3"'
        @click="$emit('click')"
        :href="href"
        :target="target"
    >
        <v-card-title
            v-if="!dense"
            style="font-size:25px"
            class="mb-5"
        >{{user.firstName}}<br />{{user.lastName}}</v-card-title>
        <v-card-subtitle>
            <div
                v-if="dense"
                class='dense'
            >
                <v-icon
                    left
                    small
                >mdi-account</v-icon>{{user.firstName}} {{user.lastName}}
            </div>
            <div
                :class='dense ? "dense" : ""'
                v-for="(icon,prop) in user_disp_data"
                :key="prop"
            >
                <v-icon
                    left
                    small
                >mdi-{{icon}}</v-icon>{{user[prop]}}
            </div>
            <v-btn
                v-if='dense'
                icon
                :color="closeIconColor"
                class='close_icon'
            >
              <v-icon>mdi-email-edit-outline</v-icon>
            </v-btn>
        </v-card-subtitle>
    </v-card>
</template>

<script>
/*
@desc Main user display card
*/
export default {
    props: ['user', 'dense', 'href', 'target'],
    data: () => ({
        user_disp_data: {
            'login': 'at',
            'email': 'email'
        },
    })
}
</script>

<style scoped>
.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>