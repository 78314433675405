<template>
    <v-container style='text-align:center'>
        <template v-if="is_success !== null">
            <v-alert :type="is_success ? 'success' : 'error'">
                {{is_success ? $t('activate.success') : $t('activate.activationimpossible')}}
            </v-alert>
            <v-btn
                :to="is_success ? ('/connect') : '/'"
                class='mt-10'
                color='primary'
                rounded
                large
                elevation="3"
            >
                <v-icon class='mr-3'>mdi-{{!is_success ? 'home' : 'account'}}</v-icon>
                {{!is_success ? $t('activate.backtohome') : $t('connect')}}
            </v-btn>
        </template>
        <v-card-text v-else>
            <h2>{{$t('activate.activation')}}</h2>
            <v-progress-circular
                class='mt-5'
                color="secondary"
                indeterminate
            >
            </v-progress-circular>
        </v-card-text>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'
/*
@desc Component routed and linked in activation email to activate accounts
*/
export default {
    data: () => ({
        is_success: null,
    }),
    methods: {
        ...mapActions(['set_back_state'])
    },
    /*
    @desc load url key & activate through API (+ valid key checking)
    */
    async mounted() {
        const key = this.$utils.get_url_args().key
        try {
            await this.$api.auth.activate(key)
            this.is_success = true
        } catch (e) {
            this.is_success = false
            console.log('ERROR', e)
            this.set_back_state('error')
        }
    }
}
</script>

<style>
</style>