<template>
    <div
        class='footer-container'
        :style="{background: $vuetify.theme.themes[theme].footer}"
    >
        <v-card
            dark
            flat
            tile
            width="100%"
            class="inner-footer text-center"
            :style="{background: $vuetify.theme.themes[theme].footer}"
        >
            <v-row>
                <v-col>
                    <v-card-text
                        v-for="(footer_row,index) in footer_data"
                        :key="index"
                        class='footer-row mb-5'
                    >
                        <span
                            v-for="footer_info in footer_row"
                            :key="footer_info.icon"
                        >
                            <v-tooltip
                                bottom
                                :disabled="!footer_info.alt"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class='mx-5 footer-data'
                                        :color="footer_info.color"
                                        :icon="!!footer_info.href"
                                        :text="!!footer_info.text"
                                        @click="footer_info.href ? go_top() : null"
                                        :to="footer_info.href == ':account' ? is_connected ? '/account' : '/connect' : footer_info.href"
                                    >
                                        <v-icon
                                            size="24px"
                                            :class='footer_info.text ? "mr-3" : ""'
                                        >mdi-{{footer_info.icon}}</v-icon>
                                        <span style="opacity:0.7">{{footer_info.text}}</span>
                                    </v-btn>
                                </template>
                                <span>{{footer_info.alt}}</span>
                            </v-tooltip>
                        </span>
                    </v-card-text>
                </v-col>

            </v-row>

            <v-divider></v-divider>

            <v-card-text style="opacity:0.5">
                {{year_display}}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
/*
@desc Main common App Footer
*/
export default {
    computed: {
        theme(){
          return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
        year_display() {
            const start_year = 2021
            const current_year = (new Date()).getFullYear()
            return `© ${start_year}${start_year == current_year ? '' : ' - ' + current_year} • Kliteam`
        },
        footer_data() {
          return [
            [
              { icon: 'lock', text: this.$t('common_footer.txt1'), color: 'secondary' },
              { icon: 'truck', text: this.$t('common_footer.txt2'), color: 'secondary' },
              { icon: 'forum', text: this.$t('common_footer.txt3'), color: 'secondary' },
            ], [
              { icon: 'home', href: '/', alt: this.$t('common_footer.home') },
              { icon: 'account', href: ':account', alt: this.$t('common_footer.account') },
              { icon: 'email', href: '/contacts', alt: this.$t('common_footer.contacts') },
              { icon: 'scale-balance', href: '/cgu', alt: this.$t('terms') },
            ]
          ]
        },
        ...mapGetters(['is_connected'])
    },
    methods: {
        /*
        @desc Scroll top when clicking on footer buttons
        */
        go_top() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }
}
</script>

<style>
.footer-container {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 200px;
    clip: rect(0, auto, auto, 0);
    background: #1e1e1e;
    z-index: 10;
}
.mobile .footer-container {
    height: 250px;
}
.inner-footer {
    position: fixed;
    bottom: 0px;
}
.footer-row {
    padding: 0px;
}
</style>