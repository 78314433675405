<template>
    <v-dialog
        v-model="display"
        width="340"
    >
        <!-- ----------------------------------------------------- TEXT ID -->
        <template v-slot:activator="{ on }">
            <v-text-field
                :background-color="backgroundColor"
                :label="label"
                :disabled="disabled"
                :dense="dense"
                :value="formattedDatetime"
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                v-on="on"
                @click="$emit('click')"
            >
                <template v-slot:progress>
                    <slot name="progress">
                        <v-progress-linear
                            color="primary"
                            indeterminate
                            absolute
                        ></v-progress-linear>
                    </slot>
                </template>
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-tabs
                    class='times_selector'
                    fixed-tabs
                    v-model="activeTab"
                >
                    <!-- ----------------------------------------------------- TABS -->
                    <v-tab
                        key="calendar"
                        v-show="false"
                    >
                        <slot name="dateIcon">
                            <v-icon>mdi-calendar</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab
                        key="timer"
                        v-show="false"
                        :disabled="dateSelected"
                    >
                        <slot name="timeIcon">
                            <v-icon>mdi-clock-outline</v-icon>
                        </slot>
                    </v-tab>
                    <!-- ----------------------------------------------------- PICKERS -->
                    <v-tab-item key="calendar">
                        <!-- ------------------------------- DATE PICKERS -->
                        <v-date-picker
                            color="primary"
                            v-model="date"
                            @input="showTimePicker"
                            full-width
                            :allowed-dates="allowed_dates"
                            :locale="$i18n.locale"
                        ></v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <!-- ------------------------------- TIME PICKERS -->
                        <v-time-picker
                            ref="timer"
                            format="24hr"
                            color="primary"
                            v-model="time"
                            :allowed-hours="given_hours_step"
                            :allowed-minutes="given_minutes_step"
                            full-width
                            @click:hour="checkHours"
                            @click:minute="okHandler"
                            :locale="$i18n.locale"
                        ></v-time-picker>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <!-- ----------------------------------------------------- ACTIONS -->
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot
                    name="actions"
                    :parent="this"
                >
                    <v-btn
                        text
                        @click.native="clearHandler"
                    >{{$t('cancel')}}</v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="okHandler"
                    >{{$t('validate')}}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns'
/*
@desc Date & Time UI selector
*/
export default {
    name: 'v-datetime-picker',
    props: ['label', 'hours_step', 'minutes_step', 'given_allowed_hours', 'dense', 'value', 'backgroundColor', 'disabled', 'start_date'],
    data() {
        return {
            display: false,
            activeTab: 0,
            date: null,
            time: null,

            date_format: 'yyyy-MM-dd',
            time_format: 'HH:mm:ss',
        }
    },
    watch: {
        datetime: function () {
            this.init()
        },
        value: function () {
            this.init()
        }
    },
    computed: {
        /*
        @desc Full date format
        */
        dateTimeFormat() {
            return `${this.date_format} ${this.time_format}`
        },
        formattedDatetime() {
            return this.selectedDatetime ? format(this.selectedDatetime, this.disp_format) : ''
        },
        selectedDatetime() {
            if (this.date && this.time) {
                let datetimeString = this.date + ' ' + this.time
                if (this.time.length === 5) {
                    datetimeString += ':00'
                }
                return parse(datetimeString, this.dateTimeFormat, new Date())
            } else {
                return null
            }
        },
        dateSelected() {
            return !this.date
        },
        /*
        @desc Allowed hours based on given steps
        */
        given_hours_step() {
            return this.given_allowed_hours ?? this.allowed(24, this.hours_step ?? 1)
        },
        /*
        @desc Allowed minutes based on given steps
        */
        given_minutes_step() {
            return this.allowed(60, this.minutes_step ?? 1)
        },
        disp_format() {
          return this.$t('time_selector.datetimeformat')
        }
    },
    methods: {
        allowed_dates(date_str) {
            const date = parse(date_str, 'yyyy-MM-dd', new Date())
            const start_date = this.start_date ?? Date.now()
            return date.getTime() > start_date
        },
        /*
        @desc Generic computing for allowed range
        */
        allowed(full, step) {
            return Array(full).fill(0).map((_, i) => i).filter(i => i % step == 0)
        },
        /*
        @desc ReCompute dates
        */
        init() {
            const date = this.value
            if (!date) return
            this.date = format(date, this.date_format)
            this.time = format(date, this.time_format)
        },
        /*
        @desc End of DateTime selection
        */
        okHandler() {
            this.resetPicker()
            this.$emit('input', this.selectedDatetime)
        },
        clearHandler() {
            this.resetPicker()
            this.date = null
            this.time = null
            this.$emit('input', null)
        },
        resetPicker() {
            this.display = false
            this.activeTab = 0
            if (this.$refs.timer) {
                this.$refs.timer.selectingHour = true
            }
        },
        checkHours(hour) {
            if (this.given_minutes_step.length == 1) {
                const min = this.given_minutes_step[0]
                this.time = hour + ':' + (min < 10 ? '0' + min : min) + ':00'
                this.okHandler()
            }
        },
        showTimePicker() {
            this.activeTab = 1
        }
    },
    mounted() {
        this.init()
    },
}
</script>

<style>
.times_selector .v-tabs-bar {
    display: none !important;
}
</style>