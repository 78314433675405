import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// COUCOU

const max_cart_time_minute = 25

let cart_time_checker_set = false
function init_cart_time_checker() {
    if (cart_time_checker_set) return
    setTimeout(() => {
        const state = store.state
        setInterval(() => {
            const now = Date.now()
            const diff = now - state.last_time_cart
            if (diff > max_cart_time_minute * 1000 * 60) {
                console.log('DELETE CART delay', max_cart_time_minute, 'min reached')
                state.cart.splice(0, 1000000)
                save_cart(state)
            }
        }, 1000)
    })
}

function save_cart(state) {
    state.last_time_cart = Date.now()
    const cart_data = { cart: state.cart, last_time_cart: state.last_time_cart }
    localStorage.setItem('cart_data', JSON.stringify(cart_data))
}

function load_cart() {
    const cart_data = JSON.parse(localStorage.getItem('cart_data') ?? '{}')
    const cart = cart_data.cart ?? []
    const last_time_cart = cart_data.last_time_cart ?? Infinity
    init_cart_time_checker()
    return { cart, last_time_cart }
}

function item_hash(item) {
    const { product_id, start_date, end_date } = item
    return product_id + start_date + end_date
}

const store = new Vuex.Store({
    state: {
        cart: load_cart().cart,
        last_time_cart: load_cart().last_time_cart,

        user: null,
        connecting: false,

        back_state: 'init',
    },
    mutations: {},
    getters: {
        cart_count(state) {
            return state.cart.length
        },
        /*
        @desc Create map from Cart content array
        */
        cart_map(state) {
            return Object.fromEntries(state.cart.map(item => [item.id, item]))
        },
        card_price(state) {
            const exact_price = state.cart.map(item => {
                const hours = (item.end_date - item.start_date) / 3600
                return item.product.price * hours
            }).reduce((a, b) => a + b, 0)
            return Math.ceil(exact_price * 100) / 10000
        },
        is_connected(state) {
            return state.user != null
        },
        is_admin(state) {
            const admin_roles = ['ROLE_MANAGER', 'ROLE_ADMIN']
            return state.user?.authorities.filter(role => admin_roles.includes(role))?.length > 0 ?? false
        },
        cart_hash(state) {
            return state.cart.map(item => item_hash(item))
        },
        cart_hash_map(_, getters) {
            return Object.fromEntries(getters.cart_hash.map(hash => [hash, true]))
        }
    },
    actions: {
        /*
        @desc Change back Illustration state string
        */
        set_back_state({ state }, back_state) {
            setTimeout(() => state.back_state = back_state, 200)
        },
        save_cart({ state }) {
            save_cart(state)
        },
        add_to_cart({ state }, product_list) {
            state.cart.push(...product_list)
            save_cart(state)
        },
        /*
        @desc Absolute set Cart content
        */
        set_cart({ state }, cart) {
            state.cart = cart
        },
        /*
        @desc Add or remove item (product + times) in card
        */
        update_cart_with({ state, getters }, item) {
            const index_in = getters.cart_hash.indexOf(item_hash(item))
            if (index_in > -1) state.cart.splice(index_in, 1)
            else state.cart.push(item)
            save_cart(state)
        },
        item_is_in_cart({ getters }, item) {
            const hash = item_hash(item)
            return getters.cart_hash.includes(hash)
        },
        remove_cart_item({ state }, item_id) {
            const index = state.cart.map(item => item.id).indexOf(item_id)
            if (index == -1) return
            state.cart.splice(index, 1)
            save_cart(state)
        },
        clear_cart({ state }) {
            state.cart.splice(0, 100000)
            save_cart(state)
        },
        /*
        @desc Set every cart item uuid (order uuid)
        */
        set_cart_uuid({ state }, uuid) {
            state.cart.forEach(item => Vue.set(item, 'order_uuid', uuid))
            save_cart(state)
        },
        /*
        @desc Remove cart item depending on there order uuid (after order purchase success)
        */
        purge_cart_from({ state }, order_uuid) {
            Vue.set(state, 'cart', state.cart.filter(item => item.order_uuid != order_uuid))
            save_cart(state)
        },
        set_user({ state }, user) {
            state.user = user
        },
        /*
        @desc Reconnect user based on saved token
        @arg2 Get User account data API method
        */
        async reconnect({ state }, get_user_api) {
            state.connecting = true
            state.user = null
            try {
                const user = await get_user_api()
                state.user = user
            } catch (e) {
                console.log('could not connect', e)
                state.connecting = false
                return false
            }
            state.connecting = false
            return true
        },
        /*
        @desc Update current connected user with new data (pull user object)
        @arg2 Get User account data API method
        */
        async upduser({ state }, get_user_api) {
            try {
                const user = await get_user_api()
                state.user = user
            } catch (e) {
                console.log('could not connect', e)
                state.connecting = false
                state.user = null
                return false
            }
            return true
        },
        disconnect({ state }) {
            state.user = null
            localStorage.removeItem('account-token')
            sessionStorage.removeItem('account-token')
        }
    },
    modules: {},
})

export default store
